exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-feed-tsx": () => import("./../../../src/templates/article-feed.tsx" /* webpackChunkName: "component---src-templates-article-feed-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-ingredient-overview-tsx": () => import("./../../../src/templates/ingredient-overview.tsx" /* webpackChunkName: "component---src-templates-ingredient-overview-tsx" */),
  "component---src-templates-ingredient-tsx": () => import("./../../../src/templates/ingredient.tsx" /* webpackChunkName: "component---src-templates-ingredient-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */)
}

